import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation";

// Splash Screen

const splashScreen = {
  enabled: true, 
  animation: splashAnimation,
  duration: 2000 
};



const illustration = {
  animated: true 
};

const greeting = {
  username: "John Strange",
  title: "Hi everyone, Im John!",
  subTitle: emoji(
    "A highly detailed Junior Developer with a passion for back end development"
  ),
  resumeLink:
    "", 
  displayGreeting: true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/JBStrange1",
  linkedin: "https://www.linkedin.com/in/john-strange-b90572258/",
  gmail: "strajb2@gmail.com",
  display: true
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "ENERGETIC AND AMBITIOUS JUNIOR DEVELOPER WHO WANTS TO EXPLORE NEW THINGS",
  skills: [
    "(❁´◡`❁) Immersed in the world of C# .Net Core, I focus on constructing robust back-end solutions for web applications.",
    emoji("(❁´◡`❁) Enthusiastic about React JS, my joy comes from crafting engaging and dynamic user interfaces."),
    emoji(
      "(❁´◡`❁) As a student programmer, I'm honing my skills in the back-end with C# .Net Core and exploring the creative side of React JS."
    )
  ],


  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    }
  ],
  display: true 
};

// Education Section

const educationInfo = {
  display: true,
  schools: [
    {
      schoolName: "Fox Valley Technical College",
      logo: require("./assets/images/FoxValleyLogo.png"),
      subHeader: "Associates in Software Development",
      duration: "January 2023 - Present",
      descBullets: [
        "Mission: Provide relevant technical education and training to support student goals, a skilled workforce, and the economic vitality of our communities. ",
        "Vision: A catalyst in engaging partners to bring innovative educational solutions to individuals, employers, and communities – transforming challenges into opportunities."
      ]
    },
    {
      schoolName: "Preble Highschool",
      logo: require("./assets/images/PrebleLogo.png"),
      subHeader: "Highschool Diploma",
      duration: "September 2018 - April 2022",
      descBullets: [
        "Mission: We are committed to the four pillars of Preble Pride which include: Respect, Responsibility, Dedication and Community. ",
        "Vision: All In for Every Student, Every Day. "
    ]
      
    }
  ]
};

// Work experience section

const workExperiences = {
  display: true, 
  experience: [
    {
      role: "Auto Technition",
      company: "Auto-Trim",
      companylogo: require("./assets/images/autoTrim.png"),
      date: "June 2023 – Present",
    },
    {
      role: "Cook",
      company: "Mangiare",
      companylogo: require("./assets/images/Mangiare.jpeg"),
      date: "May 2017 – May 2018",
    },
    {
      role: "Cook",
      company: "Parker Johns",
      companylogo: require("./assets/images/ParkerJohns.png"),
      date: "Jan 2020 – Aug 2021",
    }
  ]
};


const openSource = {
  showGithubProfile: "false",
  display: false
};



// Achievement Section


const achievementSection = {
  title: emoji("Achievements🏆 "),
  subtitle:
    "Achievements and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Google Code-In Finalist",
      subtitle:
        "First Pakistani to be selected as Google Code-in Finalist from 4000 students from 77 different countries.",
      image: require("./assets/images/codeInLogo.webp"),
      imageAlt: "Google Code-In Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dYkVvNjdNWjNybWJrbndFSHpNY2NFV1p4YmU0/view?usp=sharing"
        },
        {
          name: "Award Letter",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing"
        },
        {
          name: "Google Code-in Blog",
          url: "https://opensource.googleblog.com/2019/01/google-code-in-2018-winners.html"
        }
      ]
    },
    {
      title: "Google Assistant Action",
      subtitle:
        "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
      image: require("./assets/images/googleAssistantLogo.webp"),
      imageAlt: "Google Assistant Action Logo",
      footerLink: [
        {
          name: "View Google Assistant Action",
          url: "https://assistant.google.com/services/a/uid/000000100ee688ee?hl=en"
        }
      ]
    },

    {
      title: "PWA Web App Developer",
      subtitle: "Completed Certifcation from SMIT for PWA Web App Development",
      image: require("./assets/images/pwaLogo.webp"),
      imageAlt: "PWA Logo",
      footerLink: [
        {name: "Certification", url: ""},
        {
          name: "Final Project",
          url: "https://pakistan-olx-1.firebaseapp.com/"
        }
      ]
    }
  ],
  display: false 
};


const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "(920)241-5991",
  email_address: "Strajb2@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", 
  display: true 
};

const isHireable = false; 

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  workExperiences,
  openSource,
  achievementSection,
  contactInfo,
  twitterDetails,
  isHireable
};
